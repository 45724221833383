<template>
	<nav class="nav">
		<div class="nav__wrap">
			<a @click="goBack()" class="nav__btn nav__btn--prev" >
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10081')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<div id="container" class="container container--fit" v-if="bookings.length > 0">
		<div id="sub" class="sub booking">
			<div class="box">
				<!-- 예약 숙소 -->
				<section class="booking-top">
					<div class="box box--reverse">
						<h2 class="booking-top__title booking-top__title--small">{{t('10082')}}</h2>
						<div class="booking-top__date">
							<div class="flex">
								<span>{{t('10083')}} {{bookings[0].BOOKING_DATETIME}}</span>
							</div>
						</div>
					</div>
				</section>
				<section class="content">
					<!-- 숙소 리스트 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<div class="booking-total">
								<h3 class="booking-total__title">
									{{t('10084')}}
								</h3>
								<div class="booking-total__desc">
									{{t('10085')}}
									<span>{{bookings[0].BOOKING_NO}}</span>
								</div>
							</div>
							<div class="booking-item" v-for="booking in bookings" :key="booking.SEQ">
								<div class="booking-item__date">
									{{t('10086')}} <span>{{booking.BOOKING_NO}}</span>
								</div>
								<div class="booking-item__badge">{{t('10087')}}</div>
								<h3 class="booking-item__title">{{booking.HOTEL_NM}}</h3>
								<p class="booking-top__desc">
									<span class="booking-top__badge">{{booking.PROD_TYPE}}</span>
									{{booking.PROD_NM}}
								</p>
								<div class="booking-item__date">
									{{booking.checkin}} ~ {{booking.checkout}}<br>
									{{booking.VISIT_MEANS}}
								</div>
								<div class="booking-item__price">
									<strong>{{booking.SALES_AMT.toLocaleString("en-US")}}</strong>{{t('10088')}}
								</div>
								<div class="booking-item__select" v-if="booking.CANCEL_AVAIL_DATE.length !== 0 && availableCancel">
									<span class="description red">
										{{booking.CANCEL_AVAIL_DATE}}{{t('10089')}}
									</span>
								</div>
							</div>
						</div>
					</article>
					<article class="booking-box">
						<div class="box box--reverse">
							<!-- 예약자 정보 -->
							<div class="booking-box__item">
								<h3 class="booking-box__title"> {{t('10090')}} </h3>
								<ul class="info info--large">
									<li>
										<h4 class="info__title">
											{{t('10091')}}
										</h4>
										<div class="info__text">
											{{bookings[0].ISSUE_NM}}
										</div>
									</li>
									<li>
										<h4 class="info__title">
											{{t('10092')}}
										</h4>
										<div class="info__text">
											{{bookings[0].ISSUE_HP}}
										</div>
									</li>
								</ul>
							</div>
							<!-- 이용자 정보 -->
							<div class="booking-box__item">
								<h3 class="booking-box__title"> {{t('10093')}} </h3>
								<ul class="info info--large">
									<li>
										<h4 class="info__title">
											{{t('10091')}}
										</h4>
										<div class="info__text">
											{{bookings[0].GUEST_NM}}
										</div>
									</li>
									<li>
										<h4 class="info__title">
											{{t('10092')}}
										</h4>
										<div class="info__text">
											{{bookings[0].GUEST_HP}}
										</div>
									</li>
								</ul>
							</div>
							<!-- 숙소방문수단 -->
							<div class="booking-box__item">
								<h3 class="booking-box__title">{{t('10094')}}</h3>
								<ul class="info info--large">
									<li>
										<h4 class="info__title">
											{{t('10094')}}
										</h4>
										<div class="info__text">
											{{bookings[0].VISIT_MEANS}}
										</div>
									</li>
								</ul>
							</div>
						</div>
					</article>
					<!-- 금액 및 결제 정보 -->
					<article class="booking-box booking-box--last">
						<div class="box box--reverse">
							<h3 class="booking-box__title">{{t('10095')}}</h3>
							<ul class="info info">
								<li>
									<h4 class="info__title">
										{{t('10096')}}
									</h4>
									<div class="info__text">
										{{bookings[0].PAY_TYPE}}
									</div>
								</li>
								<li>
									<h4 class="info__title">
										{{t('10097')}}
									</h4>
									<div class="info__text">
										{{bookings[0].SALES_AMT.toLocaleString("en-US")}}{{t('10088')}}
									</div>
								</li>
								<li>
									<h4 class="info__title">
										{{t('10098')}}
									</h4>
									<div class="info__text">
										{{bookings[0].DC_AMT_CP ? "-" : ""}}{{bookings[0].DC_AMT_CP.toLocaleString("en-US")}}{{t('10088')}}
									</div>
								</li>
								<li>
									<h4 class="info__title">
										{{t('10099')}}
									</h4>
									<div class="info__text">
										{{bookings[0].DC_AMT_PT ? "-" : ""}}{{bookings[0].DC_AMT_PT.toLocaleString("en-US")}}P
									</div>
								</li>
								<li>
									<h4 class="info__title">
										{{t('10100')}}
									</h4>
									<div class="info__text">
										{{bookings[0].DC_AMT_OT ? "-" : ""}}{{bookings[0].DC_AMT_OT.toLocaleString("en-US")}}{{t('10088')}}
									</div>
								</li>
								<li class="point">
									<h4 class="info__title"> {{t('10101')}} </h4>
									<div class="info__wrap">
										<div class="info__text"><strong class="red">{{bookings[0].PAID_AMT.toLocaleString("en-US")}}</strong>{{t('10088')}}</div>
									</div>
								</li>
							</ul>
						</div>
					</article>
				</section>
			</div>
		</div>
	</div>
	<div id="container" class="container container--fit" v-else>
		<div id="sub" class="sub guest">
			<!-- content -->
			<section class="content">
				<div class="centered centered--large">
					<div class="centered__wrap">
						<div class="textbox">
							<div class="textbox__icon">
								<img src="@/assets/images/img_pencil.png" alt="예약내역이 없습니다">
							</div>
							<h3 class="textbox__title">{{t('10102')}}</h3>
                            <p class="textbox__description">{{t('10103')}}</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>

<script>
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

dayjs.locale("ko");

export default {
	setup() {
		const store = useStore();
		const router = useRouter();
		const { t }= useI18n() //번역필수 모듈
		const bookings = computed(() => {
			const findeds = store.state.mmy09.mmy0901;
			if(findeds !== undefined && findeds.length > 0) {
				return findeds.map(finded => ({
					...finded,
					BOOKING_DATETIME: dayjs(finded.BOOKING_DATETIME).format("YY.MM.DD(dd) HH:mm"),
					checkin: dayjs(finded.SDATE).format("YYYY.MM.DD (dd)") + " " + finded.CHECKIN_TIME,
					checkout: finded.SDATE === finded.EDATE ?  finded.CHECKOUT_TIME : dayjs(finded.EDATE).format("YYYY.MM.DD (dd)") + " " + finded.CHECKOUT_TIME,
				}));
			} else {
				return [];
			}
		});
		
		return {
			bookings,
			goBack: () => {
				router.back();
			},
			t,
			i18n,
		}
	},
}
</script>